import { useState, useEffect } from "react";

const useDialog = () => {
    const [dialog, setDialog] = useState({
        left: {
            open: false,
        },
        right: {
            open: false,
        },
        top: {
            open: false,
        }
    });

    useEffect(() => {
    //   console.log('is open', dialog)
    }, [dialog])
    

    const handleDialog = (anchor) => {
        setDialog({
            ...dialog,
            [anchor]: {
                open: !dialog[anchor].open
            }
        });
    }
    
    return {dialog, handleDialog}
}

export default useDialog;